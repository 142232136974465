import { accSub, accAdd } from '@/utils'
import { PAYMENT_AMOUNT_ALL, PAYMENT_AMOUNT_PRODUCT, PAYMENT_AMOUNT_FREIGHT } from '@/utils/constant/paymentConst'

export const tableCols = [
  {
    prop: 'orderCode',
    label: '订单号',
    align: 'center'
  },
  {
    prop: 'paymentOrderCode',
    label: '所属支付单号',
    align: 'center',
    slotName: 'paymentOrderCodeSlot'
  },
  {
    prop: 'orderCode',
    label: '货款',
    align: 'center'
  },
  {
    prop: 'orderCode',
    label: '运费',
    align: 'center'
  },
  {
    prop: 'orderCode',
    label: '应付总额',
    align: 'center'
  },
  {
    prop: 'orderCode',
    label: '实付总额',
    align: 'center'
  },
  {
    prop: 'produtInfo',
    label: '支付时间',
    align: 'center',
    slotName: 'produtInfoSlot'
  },
  {
    prop: 'operation',
    label: '操作',
    align: 'center',
    slotName: 'operation'
  }
]

export const option = {
  menuAlign: 'left',
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSize: 'small',
  border: true,
  expand: false,
  selection: true,
  column: [
    {
      label: '订单号',
      prop: 'orderCode',
      search: true,
      searchLabelWidth: 100,
      searchWidth: 300
    },
    {
      label: '支付单号',
      prop: 'paymentProductCode',
      // search: true,
      // searchWidth: 300,
      slot: true,
      formatter(row) {
        const { paymentAmountType } = row
        if (paymentAmountType === PAYMENT_AMOUNT_PRODUCT) {
          return row.paymentProductCode
        }
        if (paymentAmountType === PAYMENT_AMOUNT_FREIGHT) {
          return row.paymentFreightCode
        }
        return row.paymentProductCode
      }
    },
    {
      label: '货款',
      prop: 'realInternalTotalPrice',
      formatter(row) {
        const { paymentAmountType } = row
        if (paymentAmountType === PAYMENT_AMOUNT_PRODUCT) {
          return row.paymentProduct
        }
        if (paymentAmountType === PAYMENT_AMOUNT_FREIGHT) {
          return ''
        }
        return accSub(row.internalTotalPrice, row.couponAmount)
      }
    },
    {
      label: '运费',
      prop: 'paymentFreight',
      formatter(row) {
        const { paymentAmountType } = row
        if (paymentAmountType === PAYMENT_AMOUNT_PRODUCT) {
          return ''
        }
        if (paymentAmountType === PAYMENT_AMOUNT_FREIGHT) {
          return row.paymentFreight
        }
        return row.paymentFreight
      }
    },
    {
      label: '实付总额',
      prop: 'realAmount',
      formatter(row) {
        const { paymentAmountType } = row
        if (paymentAmountType === PAYMENT_AMOUNT_PRODUCT) {
          return row.paymentProduct
        }
        if (paymentAmountType === PAYMENT_AMOUNT_FREIGHT) {
          return row.paymentFreight
        }
        return accSub(accAdd(row.internalTotalPrice, row.paymentFreight), row.couponAmount)
      }
    },
    {
      label: '支付时间',
      prop: 'paymentTime',
      search: true,
      searchWidth: 500,
      type: 'daterange',
      slot: true,
      width: '150px',
      hide: true
    },
    {
      label: '支付时间',
      prop: 'paymentProductTime',
      formatter(row) {
        const { paymentAmountType } = row
        if (paymentAmountType === PAYMENT_AMOUNT_PRODUCT) {
          return row.paymentProductTime
        }
        if (paymentAmountType === PAYMENT_AMOUNT_FREIGHT) {
          return row.paymentFreightTime
        }
        return row.paymentProductTime
      }
    }
  ]
}
